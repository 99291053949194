import { userConstants } from './auth.constants';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')),
  error: null,
  loading: false,
  redirectPath: '/'
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        error: null,
        loading: false
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case userConstants.LOGIN_REDIRECT_PATH:
      return {
        ...state,
        redirectPath: action.path,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        user: null,
        error: null,
        loading: false,
      };
    default:
      return state
  }
};

export default auth;
